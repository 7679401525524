






















































































































.read-document {
  padding: 0 24px;

  &__title {
    font-weight: 600;
    font-size: 18px;
  }

  &__breadcrumbs {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #4d4d4d;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #e7e7e7;
  }

  &__content {
    padding: 16px 0;
    overflow-y: auto;

    ::v-deep p:empty::after {
      content: "\00A0";
    }
  }

  &__language-select {
    margin: 0 24px;
  }
}
